window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");
    // require('admin-lte')
    // require("admin-lte/plugins/jquery/jquery.min.js");
    // require("admin-lte/plugins/jquery-ui/jquery-ui.min.js");
    // $.widget.bridge('uibutton', $.ui.button)
    require("bootstrap");
    // require("admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js");
    //../../plugins/bs-custom-file-input/bs-custom-file-input.min.js
    // require("admin-lte/plugins/bs-custom-file-input/bs-custom-file-input.js");
    // require("admin-lte/plugins/chart.js/Chart");
    // require("admin-lte/plugins/sparklines/sparkline.js");
    // require("admin-lte/plugins/jqvmap/jquery.vmap.min.js");
    // require("admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js");
    // require("admin-lte/plugins/jquery-knob/jquery.knob.min.js");
    // require('admin-lte/plugins/moment/locales')
    require("moment");
    // require("admin-lte/plugins/daterangepicker/daterangepicker.js");
    // require('admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4') // энд нэг алдаа байна.
    //require("admin-lte/plugins/summernote/summernote-bs4");
    //require("admin-lte/plugins/overlayScrollbars/js/OverlayScrollbars.min.js");
    // require('admin-lte/dist/js/adminlte.js')
    require("admin-lte");
    // require('admin-lte/dist/js/pages/dashboard')
    //require("admin-lte/dist/js/demo.js");

    // if ('WebSocket' in window) {
    // 	(function () {
    // 		function refreshCSS() {
    // 			var sheets = [].slice.call(document.getElementsByTagName("link"));
    // 			var head = document.getElementsByTagName("head")[0];
    // 			for (var i = 0; i < sheets.length; ++i) {
    // 				var elem = sheets[i];
    // 				var parent = elem.parentElement || head;
    // 				parent.removeChild(elem);
    // 				var rel = elem.rel;
    // 				if (elem.href && typeof rel != "string" || rel.length == 0 || rel.toLowerCase() == "stylesheet") {
    // 					var url = elem.href.replace(/(&|\?)_cacheOverride=\d+/, '');
    // 					elem.href = url + (url.indexOf('?') >= 0 ? '&' : '?') + '_cacheOverride=' + (new Date().valueOf());
    // 				}
    // 				parent.appendChild(elem);
    // 			}
    // 		}
    // 		var protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
    // 		var address = protocol + window.location.host + window.location.pathname + '/ws';
    // 		var socket = new WebSocket(address);
    // 		socket.onmessage = function (msg) {
    // 			if (msg.data == 'reload') window.location.reload();
    // 			else if (msg.data == 'refreshcss') refreshCSS();
    // 		};
    // 		if (sessionStorage && !sessionStorage.getItem('IsThisFirstTime_Log_From_LiveServer')) {
    // 			console.log('Live reload enabled.');
    // 			sessionStorage.setItem('IsThisFirstTime_Log_From_LiveServer', true);
    // 		}
    // 	})();
    // }
    // else {
    // 	console.error('Upgrade your browser. This Browser is NOT supported WebSocket for Live-Reloading.');
    // }
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.baseUrl = "http://localhost:8000";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: window.location.hostname,
    wsPort: 6001,
    encrypted: false,
    disableStats: true,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'], // <-- only use ws and wss as valid transports
});
