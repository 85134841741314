<template>
  <div class="row pt-3">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-12 mt-2">
          <div class="card card-primary">
            <span
              id="centeredText"
              class="centered-text"
              v-if="
                parseInt(paragraph.start_grade) ==
                parseInt(paragraph.finish_grade)
              "
              >Түвшин: {{ paragraph.start_grade }} анги. Сэдэв:
              {{ paragraph.paragraph_title }}</span
            >
            <span id="centeredText" class="centered-text" v-else
              >Түвшин: {{ paragraph.start_grade }}-{{
                paragraph.finish_grade
              }}
              анги. Сэдэв: {{ paragraph.paragraph_title }}</span
            >
            <br />
            <p>
              <span v-for="(word, index) in words" :key="index">
                <span @click="getMyInfo(index)"> {{ word }}</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal
      size="xl"
      id="modal-paragraph-read-parent"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :title="formModalParentTitle"
      class="modal fade"
      hide-footer
      @hidden="cancelEdit1"
    >
      <div class="modal-body">Таны уншсан үгийн тоо: {{ countWord }}</div>
    </b-modal> -->
  </div>
</template>

<script>
// import Multiselect from "vue-multiselect";
export default {
  props: ["id"],
  data() {
    return {
      paragraph: {},
      clickedWordIndex: null,
      words: [],
      countWord: "",
      myChilds: [],
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",
      formModalTitle: "Сорилын дэлгэрэнгүй мэдээлэл",
      formModalParentTitle: "Хүүхэд дээрээ сорил бүртгэх",

      showSorilForm: new Form({
        id: "",
        year_id: "",
        school_id: "",
        paragraph_content_id: "",
        content_type: "", // 0-уншлага 1-цээж бичиг
        quarter_id: "",
        type: "", // явц / улирал / жил
        school_class_id: "",
        reg_emp_id: "",
        manager_id: "",
        teacher_id: "",
        parent_id: "",
        student_id: "",
        paragraph_word_correct: 0,
        paragraph_word_error: 0,
        paragraph_word_real: 0,
        paragraph_word_minute: 1,
        paragraph_mean: "",
        paragraph_mean_eval: 0,
        read: null, //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
        write: null, //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      }),

      //Эцэг эх сорил бүртгэх
      //   createSorilForm: new Form({
      //     id: "",
      //     year_id: "",
      //     school_id: "",
      //     paragraph_content_id: "",
      //     content_type: "", // 0-уншлага 1-цээж бичиг
      //     quarter_id: "",
      //     type: "явц", // явц / улирал / жил
      //     school_class_id: "",
      //     reg_emp_id: "",
      //     manager_id: "",
      //     teacher_id: "",
      //     parent_id: "",
      //     student_id: "",
      //     paragraph_word_correct: 0,
      //     paragraph_word_error: 0,
      //     paragraph_word_real: 0,
      //     paragraph_word_minute: 1,
      //     paragraph_mean: "",
      //     paragraph_mean_eval: 0,
      //     read: null, //Уншлагад тооцох эсэх 0-тооцохгүй 1-тооцно
      //     write: null, //Цээж бичигт тооцох эсэх 0-тооцохгүй 1-тооцно
      //   }),
      //   schoolParagraphContents: [],
      //   students: [],
      //   contenttypes: [
      //     { value: 0, text: "Уншлага" },
      //     { value: 1, text: "Цээж бичиг" },
      //   ],
    };
  },
  //   components: { Multiselect },
  watch: {
    // "createSorilForm.content_type"(newVal, oldVal) {
    //   //   console.log(newVal, oldVal);
    //   if (newVal === 0) {
    //     this.selectRead = true;
    //     this.createSorilForm.write = null;
    //     this.createSorilForm.read = "0";
    //   } else if (newVal === 1) {
    //     this.selectRead = false;
    //     this.createSorilForm.read = null;
    //     this.createSorilForm.write = "0";
    //   }
    // },
    // "createSorilForm.student_id"(newVal, oldVal) {
    //   // console.log(newVal, oldVal);
    //   axios
    //     .post("/parent/getMyChildsParagraphContents", {
    //       childs: newVal,
    //     })
    //     .then((res) => {
    //       //   console.log(res);
    //       this.schoolParagraphContents = res.data.schoolParagraphContents;
    //     })
    //     .catch((err) => {
    //       //   console.log(err);
    //     });
    // },
    // "createSorilForm.paragraph_content_id"(newVal, oldVal) {
    //   //   console.log(newVal, oldVal);
    //   if (Object.keys(newVal).length === 0) {
    //     this.selectPara = false;
    //     this.selectedParagraphs = "";
    //   } else {
    //     let a = this.schoolParagraphContents.filter(
    //       (el) => el.id === newVal.id
    //     );
    //     this.selectPara = true;
    //     this.selectedParagraphs = a[0];
    //     //   console.log(this.selectedParagraphs);
    //     // Fire.$emit("loadParagraphContentReads");
    //   }
    // },
  },
  computed: {
    splitWords() {
      let a = this.paragraph.paragraph_content;
      return a.split(" ");
    },
    // sumWords() {
    //   let a = this.showSorilForm.paragraph_word_real;
    //   let b = this.showSorilForm.paragraph_word_error;
    //   this.showSorilForm.paragraph_word_correct = parseInt(a) - parseInt(b);
    //   return this.showSorilForm.paragraph_word_correct;
    // },
    // sumWordsEdit() {
    //   let a = this.createSorilForm.paragraph_word_real;
    //   let b = this.createSorilForm.paragraph_word_error;
    //   this.createSorilForm.paragraph_word_correct = parseInt(a) - parseInt(b);
    //   return this.createSorilForm.paragraph_word_correct;
    // },
  },
  methods: {
    getMyInfo(text) {
      //   console.log(text);
      this.countWord = text + 1;
      Swal.fire({
        title: "<strong><u>60 хормын марафон.</u></strong>",
        icon: "success",
        html: "Таны уншсан үгийн тоо: " + "<b>" + text + "</b>" + "</br>",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Танд амжилт  <b>хүсье.</b>',
        confirmButtonAriaLabel: "Thumbs up, great!",
        // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: "Thumbs down",
      });
      //   this.$bvModal.show("modal-paragraph-read-parent");
    },
    // sortArrays(students) {
    //   return _.orderBy(students, "student_name/student_last_name", "asc");
    // },
    // orderByWords(e) {
    //   return _.orderBy(e, "paragraph_word_real", "desc");
    // },

    getParagraphContent() {
      axios
        .get("/getParagraphContent/" + this.id)
        .then((res) => {
          //   console.log(res.data);
          this.paragraph = res.data.paragraphContents;
          this.words = this.paragraph.paragraph_content.split(" ");
          //   console.log(words);
          //   this.myChilds = checkChild.filter(
          //     (el) => el.school_class_number <= 5
          //   );
        })
        .catch((errors) => {
          //   console.log(errors);
        });
    },
  },
  created() {
    // console.log(this.id);
    this.getParagraphContent();
    // Fire.$on("loadParagraphContentReads", () => {
    //   this.getMyChildParagraphReadWriteDatas(this.checkMyChild);
    // });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
p {
  font-size: 25px;
  text-align: justify;
  padding: 10pt;
}
.centered-text {
  text-align: center;
}
#centeredText {
  text-align: center;
  font-size: 30px;
}
</style>
