<template>
  <div class="col-lg-12 d-flex flex-column justify-content-center">
    <section id="services" class="services">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <header class="section-header">
          <!-- <h2>Services</h2>
          <p>Veritatis et dolores facere numquam et praesentium</p> -->
        </header>

        <div class="row gy-4">
          <div
            class="col-lg-12 col-md-6 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="service-box blue">
              <!-- <i class="ri-discuss-line icon"></i>
              <span>Өргөдөл, гомдол, санал хүсэлттэй ажиллах шатлал</span> -->
              <!-- <h3>Nesciunt Mete</h3> -->
              <p>
                <iframe
                  src="https://onedrive.live.com/embed?cid=545686A2437B5781&resid=545686A2437B5781%21116493&authkey=AJDwej1aHuuIOQo&em=2"
                  width="100%"
                  height="688"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
              </p>
              <!-- <a href="#" class="read-more"
                ><span>Read More</span> <i class="bi bi-arrow-right"></i
              ></a> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="pricing">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <header class="section-header"></header>

        <div class="row gy-4 aos-init aos-animate" data-aos="fade-left">
          <form @submit.prevent="saveParentComplain()">
            <div class="modal-body">
              <div class="direct-chat-msg">
                <div class="direct-chat-infos clearfix"></div>
              </div>
              <div class="row">
                <div class="col-lg-12 mt-2">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">
                        Санал хүсэлт илгээх (<b-link
                          href="https://1drv.ms/w/s!AoFXe0OihlZUh44PWfdZBxKvRcPMqg"
                          variant="success"
                          target="_blank"
                          >Өргөдөл, санал гомдлын хуудас татах</b-link
                        >)
                      </h3>
                    </div>
                    <!-- /.card-header -->

                    <div class="card-body table-responsive">
                      <!-- v-if="modalComments != null" -->
                      <pre class="text-right">{{ timestamp }}</pre>
                      <b-row>
                        <b-col cols="12">
                          <div class="form-group">
                            <multiselect
                              v-model="form.school_id"
                              deselect-label="Сонгосон өгөгдлийг устгах боломжгүй."
                              track-by="id"
                              label="id"
                              :custom-label="customSchoolName"
                              placeholder="Сургууль сонгох"
                              :options="schools"
                              :searchable="true"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                ><strong
                                  >{{ option.school_name }}
                                </strong></template
                              >
                            </multiselect>
                            Тухайлбал та дараах сонголтуудаас өөрийн асуудалд
                            тохирохыг сонгоно уу?
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="form-group">
                            <b-form-checkbox
                              id="checkbox-2"
                              v-model="poll"
                              name="checkbox-2"
                              value="true"
                              unchecked-value="false"
                              @change="changePoll"
                            >
                              Санал
                            </b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="form-group">
                            <b-form-checkbox
                              id="checkbox-3"
                              v-model="complaint"
                              name="checkbox-3"
                              value="true"
                              unchecked-value="false"
                              @change="changeComplaint"
                            >
                              Гомдол
                            </b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="form-group">
                            <b-form-checkbox
                              id="checkbox-4"
                              v-model="contribution"
                              name="checkbox-4"
                              value="true"
                              unchecked-value="false"
                              @change="changeContribution"
                            >
                              Хамтран ажиллах
                            </b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="form-group">
                            <b-form-checkbox
                              id="checkbox-5"
                              v-model="ethics"
                              name="checkbox-5"
                              value="true"
                              unchecked-value="false"
                              @change="changeEthics"
                            >
                              Ёс зүй
                            </b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <div class="form-group">
                            <b-form-textarea
                              id="textarea"
                              v-model="form.main_problem"
                              placeholder="Санал, гомдол, шүүмж бичих..., Хамааралтай этгээд болон үйл явдлыг дэлгэрэнгүй бичнэ үү! Хэрэв та холбогдох мэдээллээ үлдээвэл, санал гомдлын мөрөөр ажиллаж, шийдвэрийг танд мэдээллэх болно."
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <div class="form-group">
                            <p
                              class="text-sm-right"
                              size="0.6"
                              v-show="form.ethics == `true`"
                            >
                              <i
                                >Сэлбэ Сургууль Цэцэрлэгийн Цогцолборын Ёс зүйн
                                хороо мэдээллийн нууцлалыг чандлан хадгалж,
                                мэдээллийг хянаж шийдвэрлэнэ.</i
                              >
                            </p>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <div class="form-group">
                            <vue-recaptcha
                              sitekey="6LfRb7wkAAAAAHyrp53F742EJ9TLv3-s1BqODKss"
                              @verify="mxVerify"
                            ></vue-recaptcha>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <div class="form-group">
                            <span
                              v-show="isCaptchaError"
                              class="invalid-feedback"
                              style="display: block"
                            >
                              <strong
                                >Баталгаажуулах товчийг чагтална уу!</strong
                              >
                            </span>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <div class="form-group">
                            <b-overlay
                              :show="isLoadCode"
                              rounded
                              opacity="0.6"
                              spinner-small
                              spinner-variant="primary"
                              class="d-inline-block"
                            >
                              <button
                                :disabled="isLoadCode || recaptcha == null"
                                type="submit"
                                class="btn btn-success"
                              >
                                Илгээх
                              </button>
                            </b-overlay>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { VueRecaptcha } from "vue-recaptcha";
export default {
  data() {
    return {
      isLoadCode: false,
      recaptcha: null,
      isCaptchaError: false,
      //header style
      headerBgVariant: "dark",
      headerTextVariant: "black",
      bodyBgVariant: "light",

      //edit
      // Тусад нь ашиглалаа
      poll: false,
      complaint: false,
      contribution: false,
      ethics: false,
      schools: [],
      form: new Form({
        id: "",
        school_id: "",
        main_problem: "",
        poll: undefined,
        complaint: undefined,
        contribution: undefined,
        ethics: undefined,
      }),

      // Тухайн өдрийн огноо
      timestamp: "",
    };
  },
  components: {
    Multiselect,
    VueRecaptcha,
  },
  watch: {},
  methods: {
    mxVerify(response) {
      this.isCaptchaError = false;
      this.recaptcha = response;
    },
    getSomeInfo() {
      axios
        .get("/someInfo")
        .then((res) => {
          this.schools = res.data.schools;
        })
        .catch();
    },
    changePoll(checked) {
      // console.log(checked);
      if (checked) {
        this.form.poll = true;
        this.form.complaint = false;
        this.form.contribution = false;
        this.form.ethics = false;
        this.poll = true;
        this.complaint = false;
        this.contribution = false;
        this.ethics = false;
      }
    },
    changeComplaint(checked) {
      // console.log(checked);
      if (checked) {
        this.form.poll = false;
        this.form.complaint = true;
        this.form.contribution = false;
        this.form.ethics = false;
        this.poll = false;
        this.complaint = true;
        this.contribution = false;
        this.ethics = false;
      }
    },
    changeContribution(checked) {
      // console.log(checked);
      if (checked) {
        this.form.poll = false;
        this.form.complaint = false;
        this.form.contribution = true;
        this.form.ethics = false;
        this.poll = false;
        this.complaint = false;
        this.contribution = true;
        this.ethics = false;
      }
    },
    changeEthics(checked) {
      // console.log(checked);
      if (checked) {
        this.form.poll = false;
        this.form.complaint = false;
        this.form.contribution = false;
        this.form.ethics = true;
        this.poll = false;
        this.complaint = false;
        this.contribution = false;
        this.ethics = true;
      }
    },
    // Гомдол санал хадгалах функц бэлэн болсон.
    saveParentComplain() {
      this.isLoadCode = true;
      if (this.recaptcha == null) {
        this.isCaptchaError = true;
        this.isLoadCode = false;
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "error",
          timer: 5000,
          timerProgressBar: true,
          title: "Баталгаажуулах товчийг чагтална уу. Дахин оролдоно уу!",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      } else {
        this.form
          .post("/publicPoll")
          .then((response) => {
            this.isCaptchaError = false;
            this.isLoadCode = false;
            this.recaptcha = null;
            this.resetModal();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Амжилттай",
              text: "Санал хүсэлтээ илэрхийлсэн танд маш их баярлалаа.",
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((errors) => {
            // console.log(this.form.errors.get("main_problem"));
            this.isLoadCode = false;
            Swal.fire({
              icon: "error",
              title: "Алдаа гарлаа",
              text: "`Санал, гомдол, шүүмж бичих..., Хамааралтай этгээд болон үйл явдлыг дэлгэрэнгүй бичнэ үү!` гэсэн формыг бөглөнө үү?",
              footer: "Алдаагаа засна уу?",
            });
            // console.log(errors);
          });
      }
    },

    //Modal-тай ажиллах функц
    resetModal() {
      this.form.reset();
      this.poll = false;
      this.complaint = false;
      this.contribution = false;
      this.ethics = false;
    },

    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    customSchoolName({ school_name }) {
      return `${school_name}`;
    },
  },
  created() {
    this.getSomeInfo();
    setInterval(this.getNow, 1000);
  },
};
</script>

<style></style>
