//Parent
Vue.component(
    "indexComplainPublic",
    require("./components/Parent/Complaints/IndexComplainPublic.vue").default
);

Vue.component(
    "resetPass",
    require("./components/layout/resetPass.vue").default
);
Vue.component(
    "publicSatisfaction",
    require("./components/SatisfactionForm/publicSatisfaction.vue").default
);
Vue.component(
    "publicParagraph",
    require("./components/Manager/ParagraphContent/IndexPublic.vue").default
);
