<template>
  <div>
    <form v-if="step == 1">
      <b-form-group description="Та бүртгэлтэй цахим шуудангаа оруулна уу.">
        <div class="input-group">
          <input
            type="email"
            v-model="email"
            placeholder="Цахим шуудан"
            :class="[
              'form-control',
              {
                'is-invalid': isEmailError,
              },
            ]"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
      </b-form-group>
      <vue-recaptcha
        sitekey="6LfRb7wkAAAAAHyrp53F742EJ9TLv3-s1BqODKss"
        @verify="mxVerify"
      ></vue-recaptcha>
      <span v-show="isCaptchaError" class="invalid-feedback" style="display: block">
        <strong>Баталгаажуулах товчийг чагтална уу!</strong>
      </span>
      <div class="row">
        <div class="col-4">
          <b-overlay
            :show="isLoadCode"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <button
              type="button"
              :disabled="isLoadCode || recaptcha == null"
              class="btn-get-started btn-sm border-0 scrollto d-inline-flex align-items-center justify-content-center align-self-center"
              @click.prevent="sendConfirmCode()"
            >
              <span>ИЛГЭЭХ</span><i class="bi bi-arrow-right"></i>
            </button>
          </b-overlay>
        </div>
        <!-- /.col -->
      </div>
    </form>
    <form v-if="step == 2">
      <div>
        <b-alert show variant="warning">
          <strong>Баталгаажуулах кодоо оруулна уу!</strong>
          <hr />
          <p>
            Таны цахим шуудан руу <strong>Баталгаажуулах код</strong> илгээсэн байгаа.
            Баталгаажуулах кодыг зөв оруулсан тохиолдолд таны нууц үг сэргэх боломжтой.
          </p>
          <p>
            Хэрэв баталгаажуулах код цахим шууданд ирээгүй бол Junk Email эсвэл Spam
            хавтсыг шалгаарай!
          </p>
        </b-alert>
      </div>
      <div class="input-group mb-3">
        <input
          type="text"
          v-model="confirmCode"
          placeholder="Баталгаажуулах код"
          :class="[
            'form-control',
            {
              'is-invalid': isConfirmCodeError,
            },
          ]"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-check-circle"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <b-overlay
            :show="isLoadPass"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <button
              :disabled="isLoadPass || confirmCode == ''"
              class="btn-get-started btn-sm border-0 scrollto d-inline-flex align-items-center justify-content-center align-self-center"
              @click.prevent="resetPassword()"
            >
              <span>СЭРГЭЭХ</span><i class="bi bi-arrow-right"></i>
            </button>
          </b-overlay>
        </div>
        <!-- /.col -->
      </div>
    </form>
  </div>
</template>
<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  data() {
    return {
      isLoadCode: false,
      isLoadPass: false,
      step: 1,
      email: "",
      confirmCode: "",
      recaptcha: null,
      isCaptchaError: false,
      isEmailError: false,
      isConfirmCodeError: false,
    };
  },
  components: { VueRecaptcha },
  methods: {
    resetInputs() {
      this.isLoadCode = false;
      this.isLoadPass = false;
      this.step = 1;
      this.email = "";
      this.confirmCode = "";
      this.recaptcha = null;
      this.isCaptchaError = false;
      this.isEmailError = false;
      this.isConfirmCodeError = false;
    },
    mxVerify(response) {
      this.isCaptchaError = false;
      this.recaptcha = response;
    },
    sendConfirmCode() {
      this.isLoadCode = true;
      if (this.email.length < 5) {
        this.isEmailError = true;
        this.isLoadCode = false;
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "error",
          timer: 5000,
          timerProgressBar: true,
          title: "Цахим шуудан буруу байна. Дахин оролдоно уу!",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      } else if (this.recaptcha == null) {
        this.isEmailError = false;
        this.isCaptchaError = true;
        this.isLoadCode = false;
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "error",
          timer: 5000,
          timerProgressBar: true,
          title: "Баталгаажуулах товчийг чагтална уу. Дахин оролдоно уу!",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      } else
        axios
          .post("/sendConfirmCode", { email: this.email })
          .then((res) => {
            if (res.data.msj) {
              this.isEmailError = true;
              this.isLoadCode = false;
              Swal.fire({
                position: "top-end",
                toast: true,
                icon: "error",
                timer: 5000,
                timerProgressBar: true,
                title: res.data.msj,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            } else {
              this.confirmCode = "";
              this.isEmailError = false;
              this.isCaptchaError = false;
              Swal.fire({
                position: "top-end",
                toast: true,
                icon: "success",
                timer: 5000,
                timerProgressBar: true,
                title: "Таны цахим шуудан руу баталгаажуулах код илгээсэн.",
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              this.step = 2;
            }
          })
          .catch();
    },
    resetPassword() {
      this.isLoadPass = true;
      if (this.confirmCode.length > 3) {
        axios
          .post("/resetPassword", {
            email: this.email,
            confirmCode: this.confirmCode,
          })
          .then((res) => {
            if (res.data.msj) {
              this.isLoadPass = false;
              this.isConfirmCodeError = true;
              Swal.fire({
                position: "top-end",
                toast: true,
                icon: "error",
                timer: 5000,
                timerProgressBar: true,
                title: res.data.msj,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            } else {
              this.resetInputs();
              Swal.fire({
                position: "top-end",
                toast: true,
                icon: "success",
                timer: 5000,
                timerProgressBar: true,
                title: "Амжилттай. Таны цахим шуудан руу шинэ нууц үгийг илгээсэн.",
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            }
          })
          .catch();
      } else {
        this.isConfirmCodeError = true;
        this.isLoadPass = false;
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "error",
          timer: 5000,
          timerProgressBar: true,
          title: "Баталгаажуулах код буруу байна. Дахин оролдоно уу!",
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
    },
  },
  created() {},
};
</script>

<style scoped></style>
